var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.inputs.language === 'en' ? 'Determine the formal charge of each of the oxygen atoms depicted in the figure below:' : 'Déterminez la charge formelle sur chaque atome d’oxygène dans la structure suivante :')+" ")]),_c('p',{staticClass:"pl-8 mb-4"},[_c('v-img',{staticStyle:{"max-width":"280px"},attrs:{"src":_vm.imageName}})],1),_c('v-select',{staticClass:"mb-0",staticStyle:{"width":"250px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Oxygène A:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.atomA),callback:function ($$v) {_vm.$set(_vm.inputs, "atomA", $$v)},expression:"inputs.atomA"}}),_c('v-select',{staticClass:"mb-0",staticStyle:{"width":"250px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Oxygène B:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.atomB),callback:function ($$v) {_vm.$set(_vm.inputs, "atomB", $$v)},expression:"inputs.atomB"}}),_c('v-select',{staticClass:"mb-n2",staticStyle:{"width":"250px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Oxygène C:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"no-text-transform",domProps:{"innerHTML":_vm._s(item.text)}})]}}]),model:{value:(_vm.inputs.atomC),callback:function ($$v) {_vm.$set(_vm.inputs, "atomC", $$v)},expression:"inputs.atomC"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }