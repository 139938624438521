<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        {{
          inputs.language === 'en'
            ? 'Determine the formal charge of each of the oxygen atoms depicted in the figure below:'
            : 'Déterminez la charge formelle sur chaque atome d’oxygène dans la structure suivante :'
        }}
      </p>

      <p class="pl-8 mb-4">
        <v-img style="max-width: 280px" :src="imageName" />
      </p>

      <v-select
        v-model="inputs.atomA"
        :items="items"
        item-text="text"
        item-value="value"
        label="Oxygène A:"
        class="mb-0"
        style="width: 250px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.atomB"
        :items="items"
        item-text="text"
        item-value="value"
        label="Oxygène B:"
        class="mb-0"
        style="width: 250px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>

      <v-select
        v-model="inputs.atomC"
        :items="items"
        item-text="text"
        item-value="value"
        label="Oxygène C:"
        class="mb-n2"
        style="width: 250px"
      >
        <template #item="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
        <template #selection="{item}">
          <span class="no-text-transform" v-html="item.text" />
        </template>
      </v-select>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'Question494',
  components: {CalculationInput, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        atomA: null,
        atomB: null,
        atomC: null,
      },
      items: [
        {text: '-4', value: '-4'},
        {text: '-3', value: '-3'},
        {text: '-2', value: '-2'},
        {text: '-1', value: '-1'},
        {text: '0', value: '0'},
        {text: '+1', value: '1'},
        {text: '+2', value: '2'},
        {text: '+3', value: '3'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/formalChargesOxygen.png';
    },
  },
};
</script>
<style scoped></style>
